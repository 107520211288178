import React from "react"
import Layout from "../../components/layout/layout"
import SEO from "../../components/seo"
import InnerWrapper from "../../components/layout/innerWrapper"
import ConnectSidebar from "../../components/layout/connect-sidebar"
import { Link } from "gatsby"
import Iframe from "react-iframe"
import useGCLID from "../../hooks/useGCLID"

import { Row, Col, Breadcrumb } from "react-bootstrap"

const CustomerServicePage = () => {
  return (
    <Layout className="main inner-page-with-banner">
      <SEO
        title={`Walpole Outdoors - Contact Customer Service`}
        description={`The standard all other fence and outdoor structures are measured by.`}
      />

      <InnerWrapper>
        <div className="page-hero"></div>

        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/">Home</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to="/connect/customer-service">Contact Customer Service</Link>
          </Breadcrumb.Item>
        </Breadcrumb>

        <Row className="generalcontent">
          <Col md={3}>
            <ConnectSidebar />
          </Col>
          <Col md={9}>
            <h1 className="generalheader">Contact Customer Service</h1>
            <p>
              At Walpole Outdoors, we value our customers and constantly strive
              to provide the best possible experience. If you have questions
              about a recent purchase, please use the form below to reach our
              customer service department.
            </p>
            <Iframe
              className="scheduleFrame"
              url={useGCLID("https://www.tfaforms.com/4896037", "tfa_59")}
              frameBorder="0"
            />
          </Col>
        </Row>
      </InnerWrapper>
    </Layout>
  )
}

export default CustomerServicePage
